import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import { useSiteState } from '../lib/siteContext';

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

import { PageMeta } from "../components/pageMeta"
import ModuleZone from '~moduleZone';

const IndexPage = ({ data: { page } }) => {
  const { seo, pageModules, config } = page || {}
  const { bgColor, logoColor } = config || {}
  const { homeHeader, setHomeHeader, setLogoColor } = useSiteState();

  useEffect(() => {
    if (homeHeader === false) {
      setHomeHeader(true);
    }    
    if (logoColor) { 
      document.documentElement.style.setProperty(`--color-logo`, logoColor.value);
     }    
     if (bgColor.value) {
      document.documentElement.style.setProperty(`--doc-bg`, bgColor.value);      
    }    
  }, [setHomeHeader, homeHeader, logoColor, setLogoColor, bgColor ]);
  

  return (
    <>    
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} />}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query pageHomeQuery {
    page: sanityPageHome {
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }    
      config {
        logoColor {
          title
          value
        }
        bgColor {
          value
          title
        }
      }
    }
  }
`
